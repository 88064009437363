import clsx from "clsx";
import { HTMLProps } from "react";

export default function Button({
  className,
  type,
  disabled,
  color = "moss",
  ...props
}: HTMLProps<HTMLButtonElement> & {
  type?: "submit" | "reset" | "button" | undefined;
  color?:
    | "moss"
    | "red"
    | "green"
    | "blue"
    | "transparent"
    | "gray"
    | "bordered";
}) {
  return (
    <button
      className={clsx(
        "h-12 px-4 whitespace-nowrap flex items-center gap-2 transition-colors rounded-full font-medium text-base",
        {
          "bg-gray-200 text-gray-400": disabled,
          "bg-transparent active:bg-gray-200 hover:bg-gray-100":
            color === "transparent" && !disabled,
          "bg-gray-100 active:bg-gray-300 hover:bg-gray-200":
            color === "gray" && !disabled,
          "bg-medium-moss active:bg-dark-moss hover:bg-light-moss":
            color === "moss" && !disabled,
          "text-white bg-medium-berry active:bg-dark-berry hover:bg-light-berry":
            color === "red" && !disabled,
          "bg-medium-green active:bg-dark-green hover:bg-light-green":
            color === "green" && !disabled,
          "bg-medium-aqua active:bg-dark-aqua hover:bg-light-aqua":
            color === "blue" && !disabled,
          "bg-slate-50 active:bg-slate-200 hover:bg-slate-100 border border-light-smoke":
            color === "bordered" && !disabled,
        },
        className
      )}
      disabled={disabled}
      type={type}
      {...props}
    />
  );
}
