import stripeLoader from "stripe";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";

export const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY as string,
  { locale: "de" }
);

export const stripe = new stripeLoader(process.env.STRIPE_SECRET_KEY as string);

export function getStripeElementsConfig(amount: number): StripeElementsOptions {
  return {
    paymentMethodCreation: "manual",
    mode: "payment",
    capture_method: "manual",
    amount: amount * 100,
    currency: "eur",
    appearance: {
      variables: {
        fontFamily: "Inter, Helvetica, sans-serif",
        fontSizeBase: "16px",
        borderRadius: "8px",
        colorTextPlaceholder: "#636363",
        colorText: "#000",
        focusOutline: "#2563eb",
        focusBoxShadow: "0px 0px 0px 1px #1B5F40",
      },
      rules: {
        ".Input": {
          fontWeight: "400",
          fontSize: "16px !important",
          boxShadow: "none",
          padding: "12px",
          borderColor: "#d1d5db",
          marginBottom: "16px",
        },
        ".Input:focus": {
          borderColor: "#1B5F40",
        },
      },
    },
  };
}
