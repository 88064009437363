export default function StationPlaceholder() {
  return (
    <div className="w-full flex flex-col animate-pulse bg-white rounded-lg">
      <div className="py-8 px-4 relative flex flex-col gap-2">
        <div className="bg-gray-200 max-w-sm h-9 rounded-lg" />
        <div className="bg-gray-100 w-1/2 h-5 rounded-lg mb-6" />
      </div>
    </div>
  );
}
