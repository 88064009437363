import clsx from "clsx";
import { HTMLProps } from "react";

export function Card({ children, className }: HTMLProps<HTMLDivElement>) {
  return (
    <div className={clsx("w-full bg-white rounded-lg py-8 px-4", className)}>
      {children}
    </div>
  );
}
