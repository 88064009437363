import { Card } from "@/components/Card";
import { useT } from "@/utils/translation";
import { formatNumber } from "@/utils/formatNumber";
import Image from "next/image";
import { useEffect, useMemo } from "react";
import StationPlaceholder from "./StationPlaceholder";
import StatusDisplayLabel from "./StatusDisplayLabel";
import { useStation } from "./state";
import ChargingInfo from "../ChargingProcess/ChargingInfo";

const formatTariffName = (tariffName: string) => {
  return tariffName
    .replace(/(\d)([€/])/g, "$1 $2") // Adds space before € or / if missing
    .replace(/([€/])(\d)/g, "$1 $2"); // Adds space after € or / if missing
};

export default function StationInfo() {
  const { t } = useT();
  const station = useStation((s) => s.station);
  const connector = useStation((s) => s.connector);
  const isStationLoading = useStation((s) => s.isStationLoading);
  const getStationData = useStation((s) => s.getStationData);

  const showConnectorSide = useMemo(
    () =>
      station
        ? station.numberOfEVSEs > 1 && station.serialNumber.startsWith("ALF_")
        : false,
    [station]
  );

  const stationTitle = useMemo(
    () =>
      station?.operator.shortName || station?.operator.name || station?.name,
    [station]
  );

  const companyLogo = useMemo(() => {
    switch (station?.operator?.shortName) {
      case "SÜC GmbH":
        return "/images/suec.svg";
      case "Energie SaarLorLux":
        return "/images/saarLorLux.svg";
      case "Stadtwerke Schweinfurt":
        return "/images/schweinfurt.svg";
      case "Stadtwerke Saarlouis":
        return "/images/saarlouis.png";
      case "WVV":
        return "/images/wvv.png";
      default:
        return "/images/car.svg";
    }
  }, [station?.operator?.shortName]);

  useEffect(() => {
    getStationData();
  }, [getStationData]);

  if (isStationLoading) {
    return <StationPlaceholder />;
  }

  if (!station || !connector) {
    return null;
  }

  return (
    <div className="w-full flex flex-col gap-4 justify-between">
      <Card className="flex flex-row justify-between gap-2">
        <div className="flex flex-2 flex-col gap-2">
          <h1 className="text-2xl font-medium font-header">{stationTitle}</h1>

          <div className="text-sm text-light-gray">
            {station.address.street},
            <br />
            {station.address.postalCode} {station.address.city}
          </div>
        </div>

        <Image
          width={80}
          height={80}
          src={companyLogo}
          alt={station.name}
          className="self-center flex-2"
        />
      </Card>

      <Card className="flex flex-col gap-5">
        <div className="flex -mb-2">
          <div className="text-xl font-medium font-header">
            {formatNumber(connector.loadCapacity)} kW
          </div>

          <div className="text-lg font-medium">&ensp;&#x2022;&ensp;</div>

          <div className="text-xl font-medium font-header">
            {t(`connector.type.${connector.connectorType}`)}
            {showConnectorSide &&
              (connector.connectorId === "1" ? (
                <> ({t("connector.locationLeft")})</>
              ) : connector.connectorId === "2" ? (
                <> ({t("connector.locationRight")})</>
              ) : (
                connector.connectorId
              ))}
          </div>
        </div>

        <div className="text-sm text-light-gray">
          {connector.priceInfo.isFree
            ? t("tariffIsFree")
            : t("tariffLabel") +
              ": " +
              formatTariffName(connector.priceInfo.tariffName)}
        </div>

        <StatusDisplayLabel status={connector.status} />
      </Card>

      <ChargingInfo />
    </div>
  );
}
