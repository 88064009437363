import { useT } from "@/utils/translation";
import { ConnectorStatus } from "@/utils/types/ConnectorStatus.enum";
import clsx from "clsx";
import { useMemo } from "react";
import { CheckCircle as CheckIcon } from "@phosphor-icons/react/CheckCircle";
import { Info as InfoIcon } from "@phosphor-icons/react/Info";

interface Props {
  status: ConnectorStatus | null;
  className?: string;
}

export default function StatusDisplayLabel({ status, className }: Props) {
  const { t } = useT();

  const label = useMemo(() => {
    switch (status) {
      case ConnectorStatus.AVAILABLE:
        return t("connector.state.available");
      case ConnectorStatus.CHARGING:
        return t("connector.state.charging");
      case ConnectorStatus.FINISHING:
        return t("connector.state.finishing");
      case ConnectorStatus.OCCUPIED:
        return t("connector.state.occupied");
      case ConnectorStatus.UNAVAILABLE:
      default:
        return t("connector.state.unavailable");
    }
  }, [status, t]);

  const description = useMemo(() => {
    switch (status) {
      case ConnectorStatus.AVAILABLE:
        return t("connector.description.available");
      case ConnectorStatus.CHARGING:
        return t("connector.description.charging");
      case ConnectorStatus.FINISHING:
        return t("connector.description.finishing");
      case ConnectorStatus.OCCUPIED:
        return t("connector.description.occupied");
      case ConnectorStatus.UNAVAILABLE:
      default:
        return t("connector.description.unavailable");
    }
  }, [status, t]);

  const color = useMemo(() => {
    switch (status) {
      case ConnectorStatus.AVAILABLE:
      case ConnectorStatus.FINISHING:
        return "green";
      case ConnectorStatus.UNAVAILABLE:
      case ConnectorStatus.OCCUPIED:
        return "red";
      case ConnectorStatus.CHARGING:
        return "blue";
      default:
        return undefined;
    }
  }, [status]);

  const Icon = useMemo(() => {
    switch (status) {
      case ConnectorStatus.AVAILABLE:
      case ConnectorStatus.FINISHING:
        return CheckIcon;
      case ConnectorStatus.UNAVAILABLE:
      case ConnectorStatus.CHARGING:
      case ConnectorStatus.OCCUPIED:
        return InfoIcon;
      default:
        return InfoIcon;
    }
  }, [status]);

  return (
    <div
      className={clsx(
        "w-full flex items-start gap-2 p-3 rounded-lg",
        {
          "bg-light-green text-dark-green": color === "green",
          "bg-light-aqua text-dark-aqua": color === "blue",
          "bg-light-red text-dark-red": color === "red",
        },
        className
      )}
    >
      <Icon
        className={clsx("w-6 h-6", {
          "text-dark-green": color === "green",
          "text-dark-aqua": color === "blue",
          "text-dark-red": color === "red",
        })}
      />
      <div className="flex flex-col text-sm gap-1">
        <span className="font-semibold">{label}</span>
        <span>{description}</span>
      </div>
    </div>
  );
}
