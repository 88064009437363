import clsx from "clsx";
import { useEffect, useRef } from "react";
import { useChargingProcess } from "../state";
import { datadogRum } from "@datadog/browser-rum";
import { useT } from "@/utils/translation";

export default function EmailInput() {
  const { t } = useT();
  const emailRef = useRef<HTMLInputElement>(null);
  const setChargingProcessState = useChargingProcess(
    (s) => s.setChargingProcessState
  );
  const emailError = useChargingProcess((s) => s.emailError);
  const email = useChargingProcess((s) => [s.email]);

  useEffect(() => {
    if (emailError) {
      emailRef.current?.focus();
    }
  }, [emailError]);

  return (
    <div className="flex-1 flex flex-col gap-1">
      <h3 className="font-semibold text-lg">{t("emailLabel")}</h3>
      <input
        ref={emailRef}
        className={clsx(
          "border border-gray-300 rounded-lg px-3 h-12 font-sans text-base outline-none transition-colors placeholder-light-gray",
          {
            "!border-rose-600 ring-1 ring-rose-600": emailError,
            "focus:border-dark-green focus:ring-1 focus:ring-dark-green":
              !emailError,
          }
        )}
        type="email"
        autoComplete="email"
        value={email}
        onChange={(e) => {
          datadogRum.setUserProperty("email", e.target.value);
          setChargingProcessState({ emailError: false, email: e.target.value });
        }}
        placeholder={t("emailPlaceholder")}
      />
      <div className={clsx("text-rose-600", { hidden: !emailError })}>
        {t("error.email")}
      </div>
    </div>
  );
}
