import { Icon } from "@phosphor-icons/react";
import clsx from "clsx";
import { HTMLProps, ReactNode } from "react";

export type MessageColor = "blue" | "yellow" | "red" | "green" | "gray";

interface Props extends Omit<HTMLProps<HTMLDivElement>, "size"> {
  message?: ReactNode;
  icon?: Icon;
  color?: MessageColor;
  border?: boolean;
  size?: "sm" | "md";
}

export default function Message({
  message,
  icon: Icon,
  color = "blue",
  border = false,
  className,
  children,
  size = "md",
  ...props
}: Props) {
  return (
    <div
      className={clsx(
        "flex justify-center w-full rounded-lg",
        {
          "gap-2 text-sm": size === "sm",
          "py-3 px-3 gap-3 text-base": size === "md",
          border,
          "bg-light-aqua border-medium-aqua text-dark-aqua": color === "blue",
          "bg-red-100 border-medium-berry text-dark-berry": color === "red",
          "bg-light-green border-medium-green text-dark-green":
            color === "green",
          "bg-light-orange bg-opacity-70 border-medium-orange text-dark-orange":
            color === "yellow",
          "bg-slate-100 border-slate-300 text-slate-600": color === "gray",
        },
        className
      )}
      {...props}
    >
      {Icon !== undefined && (
        <Icon
          className={clsx("shrink-0", {
            "mt-0.5 text-base": size === "sm",
            "mt-[3px] text-lg": size === "md",
          })}
          weight="bold"
        />
      )}

      <div className={clsx("font-regular flex-1 text-left")}>
        {message}
        {children}
      </div>
    </div>
  );
}
