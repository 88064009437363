import clsx from "clsx";
import { useEffect, useState } from "react";
import { useT } from "@/utils/translation";

export default function ChargingStartingIndicator() {
  const { t } = useT();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (progress === 0) {
      setTimeout(() => setProgress(1), 300);
    }
  }, [progress]);

  return (
    <div className="flex justify-center items-center text-base font-medium text-white relative bg-light-moss h-10 rounded-full overflow-hidden">
      <div
        className={clsx(
          "absolute left-0 h-full w-full bg-dark-moss transform transition-transform origin-left duration-[15s] ease-out animate-pulse z-10",
          {
            "scale-x-0": progress === 0,
            "scale-x-75": progress === 1,
          }
        )}
      />

      <span className="z-10">{t("preparingCharging")}...</span>
    </div>
  );
}
