import { ReactNode, useEffect, useMemo, useState } from "react";
import { useT } from "@/utils/translation";
import { ConnectorStatus } from "@/utils/types/ConnectorStatus.enum";
import {
  ArrowRight,
  Clock,
  DownloadSimple,
  Info,
  Lightning,
} from "@phosphor-icons/react";
import Button from "@/components/Button";
import Spinner from "@/components/Spinner";
import { formatNumber } from "@/utils/formatNumber";
import clsx from "clsx";
import Message from "@/components/toasts/Message";
import StopChargingConfirmation from "./StopChargingConfirmation";
import { useStation } from "../Station/state";
import { useChargingProcess } from "./state";
import moment from "moment";
import { useAuth } from "@/features/Auth/state";

export default function ChargingInfo() {
  const { t } = useT();
  const connector = useStation((s) => s.connector);
  const token = useAuth((s) => s.token);
  const isStationLoading = useStation((s) => s.isStationLoading);
  const chargingProcessId = useChargingProcess((s) => s.chargingProcessId);
  const chargingInfo = useChargingProcess((s) => s.chargingInfo);
  const hasFinishedCharging = useChargingProcess((s) => s.hasFinishedCharging);
  const dismissChargingInfo = useChargingProcess((s) => s.dismissChargingInfo);
  const processChargingProcess = useChargingProcess(
    (s) => s.processChargingProcess
  );
  const stopCharging = useChargingProcess((s) => s.stopCharging);
  const isStoppingCharging = useChargingProcess((s) => s.isStoppingCharging);
  const isFree = Boolean(connector?.priceInfo.isFree);

  const [askingForStoppingConfirmation, setAskingForStoppingConfirmation] =
    useState(false);

  const isCharging =
    chargingProcessId && connector?.status === ConnectorStatus.CHARGING;

  const showInvoiceMessage = chargingInfo?.chargingProcess?.costs.value != 0;

  const invoiceId = chargingInfo?.chargingProcess?.invoiceId;

  const { startTime, endTime, duration } = useMemo(() => {
    if (!chargingInfo?.chargingProcess?.startTime) {
      return {};
    }

    const start = moment(chargingInfo?.chargingProcess?.startTime);
    const end = moment(chargingInfo?.chargingProcess?.endTime);
    const dur = moment.duration(
      chargingInfo?.chargingProcess?.duration?.value,
      "milliseconds"
    );
    const days = dur.days();
    const hours = dur.hours();
    const minutes = dur.minutes();
    const seconds = dur.seconds();

    const duration = `${days > 0 ? `${days}${t("day")} ` : ""}${
      hours > 0 ? `${hours} ${t("hour")} ` : ""
    }${minutes > 0 ? `${minutes} ${t("minute")} ` : ""}${
      seconds > 0 ? `${seconds} ${t("second")}` : ""
    }`;

    const isDayAfter = end.isAfter(start, "day");
    const startTime = start.format("HH:mm") + (isDayAfter ? ` (-1)` : "");
    const endTime = end.format("HH:mm");

    return {
      startTime,
      endTime,
      duration,
    };
  }, [chargingInfo, t]);

  const onStopCharging = () => {
    setAskingForStoppingConfirmation(false);
    stopCharging();
  };

  const onAskForStopConfirmation = () => {
    setAskingForStoppingConfirmation(true);
  };

  useEffect(() => {
    if (chargingProcessId && !isStationLoading) {
      processChargingProcess();
    }
  }, [chargingProcessId, processChargingProcess, isStationLoading]);

  return (
    <div>
      <div
        className={clsx(
          "flex flex-col self-stretch rounded-lg bg-white px-4 py-8",
          {
            hidden: chargingProcessId === undefined || isStationLoading,
          }
        )}
      >
        {!chargingInfo && (
          <div className="flex-1 pt-4 flex justify-center">
            <Spinner />
          </div>
        )}

        {chargingInfo && !chargingInfo.chargingProcess && (
          <div className="flex flex-col">
            <InfoRow
              label={t("chargedEnergy")}
              value={formatNumber(
                chargingInfo.energyConsumption,
                { max: 2 },
                "kWh"
              )}
              subLabel={t("chargingPower")}
              subValue={
                chargingInfo.power
                  ? formatNumber(chargingInfo.power, { max: 2 }, "kW")
                  : undefined
              }
              icon={<Lightning className="text-lg" weight="bold" />}
            />

            {Boolean(startTime) && (
              <InfoRow
                label={t("startTime")}
                value={startTime}
                subLabel={t("duration")}
                subValue={duration}
                icon={<Clock className="text-lg" weight="bold" />}
              />
            )}

            {/** Costs during charging need to be added in API */}
            {/* {Boolean(chargingInfo?.chargingCosts) && (
              <InfoRow
                label={t("chargingCost")}
                value={formatNumber(
                  chargingInfo.chargingCosts,
                  { max: 2, min: 2 },
                  "€"
                )}
                subLabel={t("duration")}
                subValue={duration}
                icon={<Coins className="text-lg" weight="bold" />}
              />
            )} */}
          </div>
        )}

        {Boolean(chargingInfo?.chargingProcess) && (
          <div className="flex flex-col gap-6">
            <div className="flex justify-between pb-4">
              <div className="font-medium">{t("summary")}</div>

              <div
                className={clsx("flex items-center gap-1 font-medium", {
                  hidden: !Boolean(startTime) || !Boolean(endTime),
                })}
              >
                {startTime}
                <ArrowRight weight="bold" className="text-xs" />
                {endTime}
              </div>
            </div>

            {chargingInfo?.chargingProcess?.consumption && (
              <CostBreakdownRow
                label={t("chargedEnergy")}
                value={formatNumber(
                  chargingInfo?.chargingProcess?.consumption.value / 1000,
                  { max: 2 },
                  "kWh"
                )}
                cost={chargingInfo?.chargingProcess?.chargingPrice?.value}
              />
            )}

            {chargingInfo?.chargingProcess?.parkingPrice && (
              <CostBreakdownRow
                label={t("costBreakdown.blocking")}
                value={duration}
                cost={chargingInfo?.chargingProcess?.parkingPrice.value}
              />
            )}

            {chargingInfo?.chargingProcess?.fixedPrice && (
              <CostBreakdownRow
                label={t("costBreakdown.flat")}
                cost={chargingInfo?.chargingProcess?.fixedPrice?.value}
              />
            )}

            {chargingInfo?.chargingProcess?.costs && (
              <CostBreakdownRow
                label={t("costBreakdown.total")}
                cost={chargingInfo?.chargingProcess?.costs?.value}
                isTotal
              />
            )}

            {chargingInfo?.chargingProcess?.fixedPrice && (
              <CostBreakdownRow
                label={t("costBreakdown.reserved")}
                cost={chargingInfo?.chargingProcess?.blockedAmount?.value}
              />
            )}
          </div>
        )}

        <div className="pt-8 flex flex-col gap-8">
          {hasFinishedCharging ? (
            <>
              {showInvoiceMessage && (
                <Message
                  size="sm"
                  color="yellow"
                  message={t("infoMessageFinished")}
                  icon={Info}
                />
              )}

              <a
                href={`/api/chargingProcess/invoice/${invoiceId}?token=${token}`}
                className={clsx(
                  "flex items-center gap-1 text-sm whitespace-nowrap underline hover:text-dark-moss",
                  { hidden: !invoiceId }
                )}
              >
                <DownloadSimple className="inline-flex" />
                {t("invoiceDownload")}
              </a>

              <Button className="justify-center" onClick={dismissChargingInfo}>
                {t("actionButtonLabelDismiss")}
              </Button>
            </>
          ) : connector?.status === ConnectorStatus.FINISHING ? (
            <div className="text-sm">
              {t("infoMessageFinishing") +
                (!isFree ? " " + t("infoMessageFinishingInvoice") : "")}
            </div>
          ) : (
            <Button
              disabled={!isCharging || isStoppingCharging}
              color="red"
              className="justify-center w-full"
              onClick={onAskForStopConfirmation}
            >
              {isCharging ? (
                <>
                  {isStoppingCharging && <Spinner />}
                  {t("actionButtonLabelStop")}
                </>
              ) : (
                <>{t("gettingChargingInfo")}...</>
              )}
            </Button>
          )}
        </div>

        <StopChargingConfirmation
          open={askingForStoppingConfirmation}
          onClose={() => setAskingForStoppingConfirmation(false)}
          onConfirm={onStopCharging}
        />
      </div>
    </div>
  );
}

interface InfoRowProps {
  label?: ReactNode;
  value?: ReactNode;
  subLabel?: ReactNode;
  subValue?: ReactNode;
  icon?: ReactNode;
}

function InfoRow({ label, value, subLabel, subValue }: InfoRowProps) {
  return (
    <div className="flex-1 flex gap-1 border-b-2 last:border-b-0 border-b-light-smoke">
      <div className="flex flex-col gap-1 flex-1">
        <div className="flex justify-between">
          <span className="font-medium">{label}</span>
          <span className="font-semibold">{value}</span>
        </div>

        {subValue !== undefined && (
          <div className="flex justify-between text-sm font-semibold text-medium-smoke">
            <span>{subLabel}</span>
            <span>{subValue}</span>
          </div>
        )}
      </div>
    </div>
  );
}

interface CostBreakdownRowProps {
  label: ReactNode;
  value?: ReactNode;
  cost?: number;
  isTotal?: boolean;
}

function CostBreakdownRow({
  label,
  value,
  cost,
  isTotal,
}: CostBreakdownRowProps) {
  return (
    <div
      className={clsx("flex-1 flex gap-1 py-1 border-b-gray-100 border-b-2")}
    >
      <div
        className={clsx("font-regular flex-1", {
          "text-lg font-bold": isTotal,
        })}
      >
        {label}
      </div>

      <div className="flex text-right gap-1">
        {value !== undefined && (
          <div className="flex justify-center items-center font-regular text-sm text-light-gray">
            {value}
          </div>
        )}

        <div
          className={clsx("font-regular min-w-28", {
            "text-lg font-bold": isTotal,
          })}
        >
          {formatNumber(cost, { min: 2, max: 2 }, "€")}
        </div>
      </div>
    </div>
  );
}
