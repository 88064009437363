import Button from "@/components/Button";
import { Info } from "@phosphor-icons/react";
import { useT } from "@/utils/translation";
import clsx from "clsx";
import { useChargingProcess } from "../state";

export default function AmountInput() {
  const { t } = useT();
  const amount = useChargingProcess((s) => s.amount);
  const setAmount = useChargingProcess(
    (s) => (amount: number) => s.setChargingProcessState({ amount })
  );

  return (
    <div className="flex flex-col gap-1">
      <h3 className="font-semibold flex justify-between items-center text-lg">
        {t("amount.label")}

        <div className="p-1 relative group cursor-pointer">
          <Info className="w-7 h-7" />

          <p className="hidden group-hover:block absolute right-0 top-full w-max max-w-xs md:max-w-md bg-white rounded-xl px-4 py-3 border border-gray-300 shadow-lg text-base font-normal z-30">
            {t("amount.info")}
          </p>
        </div>
      </h3>

      <div className="flex items-center text-base">
        <Button
          type="button"
          onClick={() => setAmount(50)}
          color="transparent"
          className={clsx(
            "border border-r-0 border-gray-300 rounded-r-none rounded-l-lg flex-1 justify-center",
            {
              "text-dark-green !border-dark-green border-2 border-r-2 font-semibold":
                amount === 50,
            }
          )}
        >
          50 €
        </Button>

        <Button
          type="button"
          onClick={() => setAmount(80)}
          color="transparent"
          className={clsx(
            "border border-l-0 border-gray-300 rounded-l-none rounded-r-lg flex-1 justify-center",
            {
              "text-dark-green !border-dark-green border-2 border-l-2 font-semibold":
                amount === 80,
            }
          )}
        >
          80 €
        </Button>
      </div>
    </div>
  );
}
