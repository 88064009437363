import Button from "@/components/Button";
import Modal from "@/components/Modal";
import { useT } from "@/utils/translation";

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export default function StopChargingConfirmation({
  open,
  onClose,
  onConfirm,
}: Props) {
  const { t } = useT();

  return (
    <Modal open={open} onClose={onClose} className="px-4 py-8 max-w-md">
      <h3 className="font-semibold text-lg mb-8">
        {t("stopChargingConfirmationTitle")}
      </h3>

      <div className="flex items-center gap-2">
        <Button
          color="red"
          className="flex-1 justify-center"
          onClick={onConfirm}
        >
          {t("actionButtonLabelStop")}
        </Button>

        <Button color="gray" onClick={onClose}>
          {t("cancel")}
        </Button>
      </div>
    </Modal>
  );
}
